import Head from "next/head";
import React, { Fragment, FunctionComponent, useEffect } from "react";
import { read } from "lib/storage";
import { DEVICE_ID_KEY } from "models/config";
import { getVersion } from "lib/version";
import ErrorBoundary from "components/error-boundary";
import { generateDeviceId, getEnv, isWindow } from "lib/env";
import {
  ActiveQuestionnaireVersions,
  HotQuestionnaireVersions,
} from "models/questionnaire";
import PaddleJS from "components/external/paddle";
import OneTrust from "components/external/onetrust";
import GTagManager from "components/external/google-tag-manager";
import { AppProps } from "next/app";
import smoothscroll from "smoothscroll-polyfill";
import { getPlatform } from "lib/platform";
import ThemeProvider from "providers/theme";
import { GlobalStyle } from "theme/global";

// some defaults
const viewPort = "width=device-width, initial-scale=1, shrink-to-fit=no";

// make a dummy read invocation to prevent circular dependency
read(DEVICE_ID_KEY);

// our dear app
const App: FunctionComponent<AppProps> = ({ Component, pageProps }) => {
  // make sure clientUuid exists
  generateDeviceId();

  // kick off the polyfill, but only inside useEffect to guarantee it is run only in browser environment
  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  return (
    <Fragment>
      <Head>
        <meta name="viewport" content={viewPort} key="viewport" />
      </Head>
      {/* Third party scripts */}
      <PaddleJS />
      <OneTrust />
      <GTagManager />

      {/* Normal content */}
      <ErrorBoundary>
        <ThemeProvider>
          <Component {...pageProps} />
          <GlobalStyle />
        </ThemeProvider>
      </ErrorBoundary>
    </Fragment>
  );
};

export default App;

// some debug things to print on initial load
if (isWindow()) {
  const v = getVersion();
  const e = getEnv();
  const p = getPlatform();

  console.groupCollapsed(`MasterEnglish (env = ${e}, platform = ${p})`);
  console.info(`Running version ${v}`);
  console.info(`Supported versions: ${ActiveQuestionnaireVersions.join(", ")}`);
  console.info(`Hot versions: ${HotQuestionnaireVersions.join(", ")}`);
  console.groupEnd();
}
