import { TranslateFn } from "models/locale";
import { OptionModel, InitialLevelMeta, TargetLevelMeta } from "models/option";
import { QuestionModel } from "models/question";
import {
  LevelQuestionRule,
  QuestionnaireModel,
  UserLevelType,
} from "models/questionnaire";
import { StateModel } from "models/state";
import { parseFollowupRules } from "./followup";
import {
  getAllQuestions,
  getAnsweredLevelQuestionTypes,
  getLastAnsweredLevelQuestion,
  getLevelQuestions,
  getInitialLevelQuestion,
  getTargetLevelQuestion,
  getQuestionById,
} from "./questionnaire";
import {
  StudyScheduleContentType,
  StudyScheduleModel,
} from "models/content/study-schedule";

export const hasContent = (question: QuestionModel, type: string) => {
  const { contents = [] } = question;
  if (!contents) {
    console.error(`Question ${question.id} does not have contents!`);
  }
  return contents.filter((c) => c.type === type).length > 0;
};

export const parseNextQuestion = (
  t: TranslateFn,
  questionnaire: QuestionnaireModel,
  question: QuestionModel,
  option: OptionModel,
  state: StateModel,
): number => {
  // check level question rules
  if (question.type === "LevelQuestion") {
    const {
      lastLevelQuestionFollowUpQuestion = FALLBACK_ID,
      levelQuestionRules = FALLBACK_RULES,
    } = questionnaire;
    const levelQuestions = getLevelQuestions(questionnaire);
    const lastLevelQuestion = levelQuestions[levelQuestions.length - 1];
    if (question.id === lastLevelQuestion.id) {
      return lastLevelQuestionFollowUpQuestion;
    }

    // note, the answer is stored in different order with old questionnaires, hence the fallback data is different
    if (option.type === "No") {
      // gather answers to an array
      for (let i = 0; i < levelQuestionRules.length; i += 1) {
        const rule = levelQuestionRules[i];
        const followUpQuestion = doesMatchlevelQuestionRule(
          questionnaire,
          state,
          rule,
        );
        if (followUpQuestion) {
          return followUpQuestion;
        }
      }
    }
  }

  // very special case(s)
  if (option.type === "ToHomePage") {
    return -1;
  } else if (option.type === "ToPrevious") {
    const { visitedQuestionIds = [] } = state;
    const lastId = visitedQuestionIds[visitedQuestionIds.length - 2]; // since the last one is the current one
    if (lastId) {
      return lastId;
    } else {
      throw new Error(`No history to go backwards!`);
    }
  }

  // find the de facto follow up question, NOTE: every option should have followUpQuestion defined
  const followUpQuestion = option.followUpQuestion;

  if (question.followUps) {
    const id = parseFollowupRules(questionnaire, question.followUps, state);
    if (id) {
      return id;
    }
  }

  // no exception case found, use initialQuestion
  const id = parseFollowUpId(question, state, followUpQuestion);
  return id;
};

export const parseFollowUpId = (
  question: QuestionModel,
  state: StateModel,
  defaultId?: number,
): number => {
  const { contents = [] } = question;
  const { schedule } = state;
  try {
    if (hasContent(question, "studySchedule")) {
      const studySchedules: StudyScheduleModel[] = [];

      // first find study schedule questions
      const studyScheduleBlocks =
        contents.filter((c) => c.type === "studySchedule") || [];
      studyScheduleBlocks.forEach((c) => {
        studySchedules.push(...(c as any).studySchedules);
      });

      // return the one
      const selected = studySchedules.find((ss) => ss.id === schedule);
      if (selected) {
        return selected.followUpQuestion;
      }
    }
  } catch (err) {
    console.error(err);
  }

  // default case
  if (defaultId) {
    return defaultId;
  }

  throw new Error("No followUpId found!");
};

const doesMatchlevelQuestionRule = (
  questionnaire: QuestionnaireModel,
  state: StateModel,
  rule: LevelQuestionRule,
) => {
  const answers = getAnsweredLevelQuestionTypes(questionnaire, state);

  const { no, maybe, followUpQuestion } = rule;
  const noCount = answers.filter((a) => a === "No").length;
  const maybeCount = answers.filter((a) => a === "Maybe").length;

  let required = 0;
  let matches = 0;
  if (no) {
    required++;
    if (noCount >= no) {
      matches++;
    }
  }

  if (maybe) {
    required++;
    if (maybeCount >= maybe) {
      matches++;
    }
  }

  if (matches === required) {
    return followUpQuestion;
  }
};
const FALLBACK_ID = 54;
const FALLBACK_RULES: LevelQuestionRule[] = [
  { no: 2, followUpQuestion: 53 },
  { no: 1, maybe: 2, followUpQuestion: 53 },
];

// NOTE: this calculates user level based on LevelQuestions and UserLevelRules
export const calculateUserLevel = (
  questionnaire: QuestionnaireModel,
  state: StateModel,
): UserLevelType => {
  // find user level
  const { userLevelAlgorithm = "LevelQuestion", userLevelMappings = [] } =
    questionnaire;

  // try with initialLevel algorithm
  if (userLevelAlgorithm === "InitialLevel") {
    const selection = getInitialLevelSelection(questionnaire, state);
    return selection && selection.userLevel ? selection.userLevel : "beginner";
  }

  // continue with level question algorithm
  const lastLevelQuestion = getLastAnsweredLevelQuestion(questionnaire, state);
  if (lastLevelQuestion) {
    const { id } = lastLevelQuestion;
    const matchingUserLevel = userLevelMappings.find((ul) => {
      const { first, last, lastNoAfter = 0, lastNoBefore = 0 } = ul;
      if (first && last) {
        // this is the newer calculus
        return id >= first && id <= last;
      }
      return id > lastNoAfter && id <= lastNoBefore;
    });
    if (matchingUserLevel) {
      return matchingUserLevel.userLevel;
    }
  }
  return "beginner";
};

export const getInitialLevelSelection = (
  questionnaire: QuestionnaireModel,
  state: StateModel,
) => {
  const { selectedOptions = [] } = state;
  const initialLevelQuestion = getInitialLevelQuestion(questionnaire);
  if (!initialLevelQuestion) {
    return undefined;
  }
  const { id, options = [] } = initialLevelQuestion;

  // find matching option
  const selectedOption = selectedOptions.find((so) => so.questionId === id);
  if (!selectedOption) {
    return undefined;
  }

  // parse meta
  const option = options.find((o) => o.id === selectedOption.optionId);

  if (!option) {
    throw new Error("No userLevelOption found!");
  }

  // support for both new meta and old version, where the fields are embedded
  const meta = (option.meta || option) as InitialLevelMeta;
  return meta;
};

export const getTargetLevelSelection = (
  questionnaire: QuestionnaireModel,
  state: StateModel,
) => {
  const { selectedOptions = [] } = state;
  const targetLevelQuestion = getTargetLevelQuestion(questionnaire);
  if (!targetLevelQuestion) {
    return undefined;
  }
  const { id, options = [] } = targetLevelQuestion;

  // find matching option
  const selectedOption = selectedOptions.find((so) => so.questionId === id);
  if (!selectedOption) {
    return undefined;
  }

  // parse meta
  const option = options.find((o) => o.id === selectedOption.optionId);

  if (!option) {
    throw new Error("No TargetLevelOption found!");
  }

  // support for both new meta and old version, where the fields are embedded
  const meta = (option.meta || option) as TargetLevelMeta;
  return meta;
};

// NOTE the values are smaller for the old variants, as the answers are registered in different order
export const getStudySchedule = (
  questionnaire: QuestionnaireModel,
  state: StateModel,
) => {
  const { schedule } = state;

  // no state yet
  if (!schedule) {
    return undefined;
  }
  const questions = getAllQuestions(questionnaire);
  const studySchedules: StudyScheduleModel[] = [];

  // first find study schedule questions
  questions
    .filter((q) => {
      return hasContent(q, "studySchedule");
    })
    .forEach((q) => {
      const studyScheduleBlocks =
        q.contents?.filter((c) => c.type === "studySchedule") || [];
      studyScheduleBlocks.forEach((c) => {
        studySchedules.push(...(c as any).studySchedules);
      });
    });

  // return the one
  const selected = studySchedules.find((ss) => ss.id === schedule);
  return selected;
};

export const getFollowUpIds = (question: QuestionModel) => {
  const followUpIds: number[] = [];
  const { options = [], contents = [], followUps = [] } = question;

  const add = (id: number) => {
    if (id !== undefined && id !== -1 && !followUpIds.includes(id)) {
      followUpIds.push(id);
    }
  };

  // add options
  options.forEach((o) => {
    if (o.followUpQuestion) {
      add(o.followUpQuestion);
    }
  });
  followUps.forEach((fu) => add(fu.followUpQuestion));

  // special contents
  try {
    contents.forEach((content) => {
      switch (content.type) {
        case "studySchedule":
          (content as StudyScheduleContentType).studySchedules.forEach((ss) =>
            add(ss.followUpQuestion),
          );
          break;
      }
    });
  } catch (err) {}

  return followUpIds;
};

export const isSignupQuestion = (
  questionId: number,
  questionnaire: QuestionnaireModel,
) => {
  const question = getQuestionById(questionnaire, questionId);

  if (question) {
    return question.type === "SignupView";
  }

  return false;
};
