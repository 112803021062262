import { read, write } from "lib/storage";
import { DEFAULT_THEME, ThemeType, THEME_KEY } from "models/theme";
import { useRouter } from "next/router";
import React, {
  createContext,
  FunctionComponent,
  useEffect,
  useState,
} from "react";

interface ThemeContextInterface {
  theme: ThemeType;
  updateTheme: (newTheme: ThemeType) => void;
}

// create context
export const ThemeContext = createContext<ThemeContextInterface | undefined>(
  undefined
);

const ThemeProvider: FunctionComponent = ({ children }) => {
  const router = useRouter();
  const [theme, toggleTheme] = useState<ThemeType>(DEFAULT_THEME);

  const updateTheme = (newTheme: ThemeType) => {
    write(THEME_KEY, newTheme);
    toggleTheme(newTheme);
  };

  useEffect(() => {
    if (router.isReady) {
      const cachedTheme = read<ThemeType>(THEME_KEY);
      const queryTheme = router.query["theme"] as ThemeType;

      // try to reason theme value (prioritise query to enabled testing)
      let newTheme: ThemeType = DEFAULT_THEME;
      if (queryTheme) {
        // use from url, if set
        newTheme = queryTheme;
      } else if (cachedTheme) {
        // use cached, if set
        newTheme = cachedTheme;
      }

      // change state
      updateTheme(newTheme);
    }
  }, [router.isReady]);

  const value = { theme, updateTheme };
  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export default ThemeProvider;
