import { NextRouter } from "next/router";

export type MediaExtension =
  | "png"
  | "jpg"
  | "svg"
  | "webp"
  | "gif"
  | "mp4"
  | "mov"
  | "vtt";
export type MediaSize = "1x" | "2x" | "3x";

interface MediaOptionsModel {
  extension?: MediaExtension;
  size?: MediaSize;
}

// for a single url
export const getMediaUrl = (
  image: string,
  router: NextRouter,
  options: MediaOptionsModel = {}
) => {
  const { extension = "png", size = "1x" } = options;
  const finalSize = size !== "1x" ? `@${size}` : "";
  const filename = `${image}${finalSize}.${extension}`;
  return [router.basePath, "media", filename].join("/");
};

export const getType = (extension: MediaExtension) => {
  switch (extension) {
    case "mp4":
      return "video/mp4";
    case "jpg":
      return "image/jpeg";
    case "png":
      return "image/png";
    case "svg":
      return "image/svg+xml";
    case "webp":
      return "image/webp";
  }
};

export interface MediaUrls {
  mobile: string;
  mobileWebp?: string;
  tablet: string;
  tabletWebp?: string;
  desktop: string;
  desktopWebp?: string;
}
