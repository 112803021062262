import { useConfig } from "hooks/config";
import {
  useQuestionnaireState,
  useUpdateQuestionnaireState,
} from "hooks/state";
import { useUpdateTheme } from "hooks/theme";
import { getVersion } from "lib/questionnaire";
import { syncState } from "lib/state";
import { read, write } from "lib/storage";
import {
  QuestionnaireModel,
  QuestionnaireVersion,
  QUESTIONNAIRE_KEY,
  isHot,
  VERSION_KEY,
} from "models/questionnaire";
import { useRouter } from "next/router";
import React, {
  createContext,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import { loadCleanQuestionnaire } from "services/questionnaire";

interface QuestionnaireContextInterface {
  questionnaire: QuestionnaireModel;
  updateQuestionnaire: (newQuestionnaire: QuestionnaireModel) => void;
}

// create context
export const QuestionnaireContext = createContext<
  QuestionnaireContextInterface | undefined
>(undefined);

interface QuestionnaireProviderProps {
  version: QuestionnaireVersion;
  questionId: number;
}

const QuestionnaireProvider: FunctionComponent<QuestionnaireProviderProps> = ({
  children,
  version,
  questionId,
}) => {
  const [questionnaire, toggleQuestionnaire] = useState<
    QuestionnaireModel | undefined
  >(undefined);
  const updateQuestionnaireState = useUpdateQuestionnaireState();
  const [loaded, toggleLoaded] = useState(false);
  const router = useRouter();
  const state = useQuestionnaireState();
  const config = useConfig();
  const updateTheme = useUpdateTheme();
  const { uuid } = config;

  const updateQuestionnaire = (newQuestionnaire: QuestionnaireModel) => {
    // change local state
    toggleQuestionnaire(newQuestionnaire);
    console.info(`Updated Questionnaire Context`);

    // store version info
    write<QuestionnaireVersion>(VERSION_KEY, getVersion(newQuestionnaire));
    write<QuestionnaireModel>(QUESTIONNAIRE_KEY, newQuestionnaire);
  };

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    const fn = async () => {
      console.info(`QuestionnaireProvider, v=${version} and q=${questionId}`);

      if (!questionnaire || getVersion(questionnaire) !== version) {
        console.info(` ==> Context is empty, trying to load...`);

        // first try from cache
        let newQuestionnaire =
          read<QuestionnaireModel>(QUESTIONNAIRE_KEY) || undefined;
        if (newQuestionnaire && getVersion(newQuestionnaire) === version) {
          // just log here
          console.info(` ==> Loaded from localStorage.`);
        }

        // if using hot version, make sure we don't use cached version
        if (isHot(version)) {
          newQuestionnaire = undefined;
          console.info(` ==> Used version is "hot", ignore localStorage.`);
        }

        // if we don't have a version in cache or it is wrong version, then we need to fetch it again
        if (
          newQuestionnaire === undefined ||
          getVersion(newQuestionnaire) !== version
        ) {
          newQuestionnaire = await loadCleanQuestionnaire(uuid, version, true);
        }

        // force update theme, if needed
        if (
          newQuestionnaire.theme !== undefined &&
          router.query.theme === undefined
        ) {
          console.info(`force theme ${newQuestionnaire.theme}`);
          updateTheme(newQuestionnaire.theme);
        }

        // toggle
        updateQuestionnaire(newQuestionnaire);
      } else {
        console.info(` ==> Switched to question ${questionId}`);
      }

      // persist to state
      syncState(state, version, questionId);
      updateQuestionnaireState(state);

      // mark loaded
      toggleLoaded(true);
    };
    fn();
  }, [questionId, router.isReady]); // make sure the questionId changes the state!

  if (!questionnaire || !loaded) {
    return null;
  }
  return (
    <QuestionnaireContext.Provider
      value={{
        questionnaire,
        updateQuestionnaire,
      }}
    >
      {children}
    </QuestionnaireContext.Provider>
  );
};

export default QuestionnaireProvider;
