import Script from "next/script";
import React, { Fragment, FunctionComponent } from "react";

const code = `
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", "${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}");
`;

const GTagManager: FunctionComponent = () => {
  const isGTMEnabled = process.env.NEXT_PUBLIC_GTM_ID === "true";
  if (!isGTMEnabled) {
    return null;
  }

  return (
    <Fragment>
      <Script id="gtm-load-and-init" strategy="afterInteractive">
        {code}
      </Script>
    </Fragment>
  );
};
export default GTagManager;
