import React, { Fragment, FunctionComponent, Suspense } from "react";
import SharedHead from "components/head";
import { StyledView, StyledViewWrapper } from "./view.styles";
import ViewProvider from "providers/view";
import { ViewType } from "models/view";
import { useTheme } from "hooks/theme";

const LazyFooter = React.lazy(() => import("../footer/footer"));

interface ViewProps {
  title: string;
  description: string;
  image?: string;
  noindex?: boolean;
  view?: ViewType;
  footer?: boolean;
  showLogoInFooter?: boolean;
  cover?: boolean;
}

const View: FunctionComponent<ViewProps> = ({
  children,
  title,
  description,
  image,
  noindex = false,
  view = "light",
  footer = false,
  showLogoInFooter = false,
  cover = false,
}) => {
  const theme = useTheme();
  return (
    <Fragment>
      <SharedHead
        title={title}
        description={description}
        image={image}
        noindex={noindex}
      ></SharedHead>
      <ViewProvider type={view}>
        <StyledViewWrapper $cover={cover}>
          <StyledView $view={view} $theme={theme}>
            {children}
          </StyledView>
          {footer && (
            <Suspense fallback={<div />}>
              <LazyFooter logo={showLogoInFooter} />
            </Suspense>
          )}
        </StyledViewWrapper>
      </ViewProvider>
    </Fragment>
  );
};

export default View;
