import { ViewType } from "models/view";
import React, { createContext, FunctionComponent } from "react";

interface ViewContextInterface {
  type: ViewType;
}

export const ViewContext = createContext<ViewContextInterface | undefined>(
  undefined
);

interface ThemeProviderProps {
  type: ViewType;
}

const ViewProvider: FunctionComponent<ThemeProviderProps> = ({
  type,
  children,
}) => {
  return (
    <ViewContext.Provider value={{ type }}>{children}</ViewContext.Provider>
  );
};

export default ViewProvider;
