import React, { FunctionComponent } from "react";
import { useTranslations } from "hooks/translations";
import { StyledLoader } from "./loader.styles";
import View from "components/layout/view";
import Wait from "./wait";

const Loader: FunctionComponent = () => {
  const t = useTranslations();
  return (
    <View noindex title={t("Loading")} description="" cover>
      <StyledLoader>
        <Wait text={t("Loading")} />
      </StyledLoader>
    </View>
  );
};

export default Loader;
