import { request } from "lib/request";
import { getVersion } from "lib/version";
import { ConfigModel } from "models/config";

interface Options {
  campaignId?: string;
  versionId?: string;
}

export const fetchConfig = async (options: Options = {}) => {
  const { campaignId, versionId } = options;

  const params: any = {
    version: `v${getVersion()}`,
    uiLanguage: "es-419",
  };

  if (campaignId) {
    params.campaignId = campaignId;
  }

  if (versionId) {
    params.versionId = versionId;
  }

  const response = await request<ConfigModel>({
    method: "GET",
    params, // these are fixed for now
    url: "/v1/config",

    // not not auth this endpoint
    authenticated: false,
  });

  return response.data;
};
