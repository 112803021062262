import { ConfigModel } from "models/config";
import { TranslateFn } from "models/locale";
import {
  QuestionnaireModel,
  QuestionnaireVersion,
  QUESTIONNAIRE_KEY,
  VERSION_KEY,
} from "models/questionnaire";
import { StateModel } from "models/state";
import {
  TOKEN,
  REFRESH_TOKEN,
  SIGNUP_EMAIL_KEY,
  SIGNUP_NAME_KEY,
} from "models/user";
import { NextRouter } from "next/router";
import { fetchEntitlement } from "services/entitlement";
import {
  loadSavedQuestionnaire,
  saveQuestionnaireState,
} from "services/questionnaire";
import { LoginResponseModel, refreshTokens } from "services/user";
import {
  getProgramView,
  getQuestionById,
  getQuestionnaireUrl,
  getStaticUrl,
  getVersion,
  isFinished,
} from "./questionnaire";
import { buildState, buildUser, isStateValid } from "./state";
import { clearUserSelectionsFromState, write } from "./storage";
import { parseNextQuestion } from "./question";
import { StaticViewPathEnum } from "models/question";
import { isEntitlementActive, isEntitlementExpired } from "./entitlement";

interface RestoreProps {
  t: TranslateFn;
  config: ConfigModel; // comes from hook originally
  router: NextRouter;

  // we can use target to overwrite questionnaire version
  target?: QuestionnaireVersion;

  // this is optional, if we are inside the questionnaire, it is required to update react context
  updateQuestionnaire: (newQuestionnaire: QuestionnaireModel) => void;
  updateQuestionnaireState: (newState: StateModel) => void;
}

// NOTE: all the data here should, if possible, come from server as the restore might happen in fresh browser session
export const restore = async (props: RestoreProps) => {
  const {
    t,
    target,
    router,
    config,
    updateQuestionnaire,
    updateQuestionnaireState,
  } = props;
  console.info(`Starting restore with UUID = ${config.uuid}`);

  // clear a few things (signup_email and signup_name remain)
  // NOTE: this might be too vague
  clearUserSelectionsFromState();

  // restore questionnaire
  const response = await restoreQuestionnaire(config, target);
  const { questionnaire: restoredQuestionnaire, state: restoredState } =
    response;

  // update questionnaire and state in context
  updateQuestionnaire(restoredQuestionnaire);
  updateQuestionnaireState(restoredState);

  // save new variant, if target attr is defined
  if (target) {
    console.info(
      `target = ${target}, restoredState.finished = ${restoredState.finished}`,
    );
    await saveQuestionnaireState(
      restoredQuestionnaire,
      { ...restoredState, id: target }, // force new id
      restoredState.finished,
    );
  }

  // take user to the questionnaire
  await restoreLocation(restoredQuestionnaire, restoredState, router, t);
};

export const restoreLocation = async (
  questionnaire: QuestionnaireModel,
  state: StateModel,
  router: NextRouter,
  t: TranslateFn,
) => {
  console.info("Restore location");
  console.info("State is now:");
  console.info(state);
  const version = getVersion(questionnaire);

  // if we have entitlement, redirect to handle your subscription
  const entitlement = await fetchEntitlement();
  if (entitlement) {
    if (isEntitlementActive(entitlement)) {
      console.info("Active entitlement found:");
      console.info(entitlement);
      const url = t("/manage-your_plan");
      router.replace(url);
      return;
    } else if (isEntitlementExpired(entitlement)) {
      console.info("Expired entitlement found:");
      console.info(entitlement);
      const url = getStaticUrl(t, version, StaticViewPathEnum.REACTIVATE);
      router.replace(url);
      return;
    } else {
      // if we end up here, it means there is no entitlement at all ==> proceed to further restore logic
    }
  }

  // find program view
  const programView = getProgramView(questionnaire, state);

  // if the questionnaire is finished, move to somewhere else
  if (isFinished(state)) {
    console.info("Finished state, redirect to programview.");
    // get program view
    router.replace(getQuestionnaireUrl(t, version, programView.id));
    return;
  }

  // take the user to the correct screen
  const url = getNextUrl(questionnaire, state, t, version);
  router.replace(url);
};

const getNextUrl = (
  questionnaire: QuestionnaireModel,
  state: StateModel,
  t: TranslateFn,
  version: QuestionnaireVersion,
) => {
  // here the currentQuestionId is the id of the last saved question
  const { currentQuestionId, selectedOptions } = state;

  // get the question and answer for the "last saved question"
  const question = getQuestionById(questionnaire, currentQuestionId);
  const so = selectedOptions.find((so) => so.questionId === currentQuestionId);
  if (!question || !so) {
    return getQuestionnaireUrl(t, version, currentQuestionId);
  }

  // find option
  const { options = [] } = question;
  const option = options.find((o) => o.id === so.optionId);
  if (!option) {
    return getQuestionnaireUrl(t, version, currentQuestionId);
  }

  // finally, apply logic and get us the correct id
  const questionId = parseNextQuestion(
    t,
    questionnaire,
    question,
    option,
    state,
  );
  return getQuestionnaireUrl(t, version, questionId);
};

interface RestoreSessionProps {
  config: ConfigModel;
  updateConfig: (newConfig: ConfigModel) => void;
  tempToken: string;
}

export const restoreSession = async (props: RestoreSessionProps) => {
  const { tempToken, config, updateConfig } = props;

  const response = await refreshTokens(tempToken);
  const { token, refreshToken, user } = response;

  // store new tokens to sessionStorage
  write(TOKEN, token);
  write(REFRESH_TOKEN, refreshToken);

  // store user email and firstname for further usage (in case we skip signup for example)
  write<string>(SIGNUP_EMAIL_KEY, user.email);
  write<string>(SIGNUP_NAME_KEY, user.firstname);

  // modify config, persist to state and return
  if (!user.uuid) {
    throw new Error("No user.uuid found!");
  }

  // set some stuff from the new config
  config.uuid = user.uuid;
  config.questionnaireVersion = parseQuestionnaireVersion(response);
  config.completed = parseQuestionnaireCompleted(response);
  config.entitlementExists = parseEntitlementExists(response);

  console.info(
    `Set uuid => ${config.uuid}, questionnaireVersion => ${config.questionnaireVersion}, completed = ${config.completed}`,
  );
  updateConfig(config);
};

export const parseQuestionnaireCompleted = (response: LoginResponseModel) => {
  const { features } = response;
  return features.questionnaire.completed || false;
};

export const parseEntitlementExists = (response: LoginResponseModel) => {
  const { features } = response;
  return features.questionnaire.entitlementExists || false;
};

export const parseQuestionnaireVersion = (
  response: LoginResponseModel,
): QuestionnaireVersion => {
  const { features } = response;
  return features.questionnaire.version;
};

const restoreQuestionnaire = async (
  config: ConfigModel,
  target?: QuestionnaireVersion,
) => {
  // fetch questionnaire without version info (returns either saved one or V9 by default)
  const response = await loadSavedQuestionnaire(config, target);
  let { state } = response;
  const { questionnaire } = response;

  // if the state is invalid/empty, then clear state at this point (next lines will reinit state)
  if (!isStateValid(state)) {
    console.info("Invalid or missing state, create an empty state!");
    state = buildState(config);

    // mark signup done, so we will skip the signup
    state.signupDone = true;

    // force false, so we go to the beginning of the questionnaire (otherwise the program page will contain invalid data)
    state.finished = false;
  } else {
    // do nothing for the existing, valid state
  }

  // inject params to state
  state.id = getVersion(questionnaire);
  state.user = state.user || { ...buildUser(config) }; // this is needed in case the original state does not have user defined

  // log
  console.info("Restored state is:");
  console.info(state);

  // store to sessionStorage
  write<QuestionnaireVersion>(VERSION_KEY, getVersion(questionnaire));
  write<QuestionnaireModel>(QUESTIONNAIRE_KEY, questionnaire);
  write<string>(SIGNUP_EMAIL_KEY, state.user.email);
  write<string>(SIGNUP_NAME_KEY, state.user.firstname);

  // return instance
  return { questionnaire, state };
};
