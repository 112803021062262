import { ProductType } from "models/product";
import { QuestionnaireVersion } from "models/questionnaire";
import { Currency } from "./currency";
import { isWindow } from "./env";
import shajs from "sha.js";

const shaEmail = (email: string) => {
  // NOTE: this might not be used in GTM as GTM parses the email values from HTML DOM
  return shajs("sha256").update(email).digest("hex");
};

const gtmEvent = (eventName: string, eventDetails?: unknown) => {
  if (isWindow()) {
    (window as any).dataLayer.push({
      event: eventName,
      details: eventDetails,
    });
  }
};

export const gtmUserId = (uuid: string) => {
  gtmEvent("user_id", {
    uuid,
  });
};

export const gtmSignUp = (email: string) => {
  gtmEvent("me_sign_up", {
    sha256_email_address: shaEmail(email),
    event_label: "registration",
  });
};

// pure js exceptions
export const gtmJSException = (
  error: any,
  userAgent: string,
  stack?: string,
  componentStack?: string,
) => {
  const { message } = error;
  gtmEvent("js-exception", { message, stack, componentStack, userAgent });
};

// to track endpoint originated issues
export const gtmException = (
  status_code: number,
  endpoint: string,
  message: string,
) => {
  gtmEvent("exception", { status_code, endpoint, message });
};

export const gtmRequest = (response_code: number, endpoint: string) => {
  gtmEvent("me_web_request", {
    response_code,
    endpoint,
  });
};

export const gtmRequestDuration = (
  request_duration: number,
  response_code: number,
  endpoint: string,
) => {
  gtmEvent("me_web_request_duration", {
    request_duration,
    response_code,
    endpoint,
  });
};

export const gtmPurchaseDone = (
  email: string,
  productType: ProductType,
  productPrice: number,
  currency: Currency,
  version: QuestionnaireVersion,
) => {
  gtmEvent("me_web_purchase_done", {
    sha256_email_address: shaEmail(email),
    productType,
    productPrice,
    currency,
    version,
  });
};

export const gtmSubcription = (
  email: string,
  productType: ProductType,
  productPrice: number,
  currency: Currency,
  version: QuestionnaireVersion,
) => {
  gtmEvent("me_web_subscription", {
    sha256_email_address: shaEmail(email),
    productType,
    productPrice,
    currency,
    version,
  });
};

export const gtmHomepage = () => {
  gtmEvent("me_web_homepage");
};
