import { checkRequirements } from "lib/requirement";
import { replaceTags } from "lib/string";
import { TagMap, getUserTags } from "lib/tag";
import { ConfigModel } from "models/config";
import { SlideshowContentType } from "models/content/slideshow";
import { TitleContentType } from "models/content/title";
import { QuestionModel } from "models/question";
import { QuestionnaireModel } from "models/questionnaire";
import { StateModel } from "models/state";

const parseText = (
  questionnaire: QuestionnaireModel,
  question: QuestionModel,
  type: "title" | "description",
  state?: StateModel,
  config?: ConfigModel
) => {
  // find content that matches the requirements
  const { contents = [] } = question;
  const content = contents.find((c) => {
    return (
      c.type === type &&
      checkRequirements({
        questionnaire,
        state,
        requirements: c.requirements,
        config,
      })
    );
  }) as TitleContentType;

  // return if content
  if (content) {
    // replace tags with pseudomised something, to avoid leakage of user data in analytics or in html <title>
    const tags: TagMap = { FIRSTNAME: "..." };
    return replaceTags(content.text, tags);
  }
  return "";
};

export const getQuestionTitle = (
  questionnaire: QuestionnaireModel,
  question: QuestionModel,

  // these are optional
  state?: StateModel,
  config?: ConfigModel
) => {
  let title = parseText(questionnaire, question, "title", state, config);

  if (!title) {
    // try to use slideshow's first text instead
    const { contents = [] } = question;
    const slideshow = contents.find((c) => c.type === "slideshow");
    if (slideshow) {
      const { slideshowOptions = {} } = slideshow as SlideshowContentType;
      const { items = [] } = slideshowOptions;
      if (items[0]) {
        title = items[0].text;
      }
    }
  }

  return title;
};

export const getQuestionDescription = (
  questionnaire: QuestionnaireModel,
  question: QuestionModel,

  // these are optional
  state?: StateModel,
  config?: ConfigModel
) => parseText(questionnaire, question, "description", state, config);
