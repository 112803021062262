import React, { Fragment, FunctionComponent, useState } from "react";
import Script from "next/script";

const isSandbox = process.env.NEXT_PUBLIC_PADDLE_SANDBOX === "true";
const initScript = `
  ${isSandbox ? 'Paddle.Environment.set("sandbox");' : ""}
  Paddle.Setup({
    vendor: ${process.env.NEXT_PUBLIC_PADDLE_VENDOR_ID},
    eventCallback: function (data) {
      // Paddle react component will add delegatePaddleEvent method to handle events in a proper scope
      window.delegatePaddleEvent(data);
    },
  });
`;

const PaddleJS: FunctionComponent = () => {
  const [loaded, toggleLoaded] = useState(false);
  const [init, toggleInit] = useState(false);
  return (
    <Fragment>
      <Script
        id="paddle-load"
        src="https://cdn.paddle.com/paddle/paddle.js"
        onLoad={() => {
          toggleLoaded(true);
          setTimeout(() => toggleInit(true), 1000);
        }}
      />
      {loaded && !init && <Script id="paddle-init">{initScript}</Script>}
    </Fragment>
  );
};

export default PaddleJS;
