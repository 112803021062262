import { isWindow } from "lib/env";
import { gtmJSException } from "lib/gtm";
import React, { ErrorInfo } from "react";

export const reportException = (
  message: string,
  stack?: string,
  componentStack?: string
) => {
  const { userAgent } = navigator;
  gtmJSException(message, userAgent, stack, componentStack);
};

class ErrorBoundary extends React.Component {
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // report error
    reportException(error.message, error.stack, errorInfo.componentStack);
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;

if (isWindow()) {
  // add global event tracker, this will catch errors not handled by <ErrorBoundary />
  (window as any).onerror = function (
    message: any,
    source: any,
    lineno: any,
    colno: any,
    error: any
  ) {
    reportException(message, error.stack);
  };
}
