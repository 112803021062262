import { MediaExtension } from "lib/media";
import { ContentType } from "./content";
import { OptionModel } from "./option";
import { FollowUpModel } from "./rules";

export interface QuestionModel {
  id: number;
  type: QuestionType;

  // weight in progress calculus, defaults to 1
  weight?: number;

  // schema
  navbar?: boolean;
  tiles?: boolean;
  progress?: boolean;
  dark?: boolean;
  footer?: boolean;

  // new contents
  contents?: ContentType[];

  // footnote text below buttons
  footnote?: string;

  // wallpaper image
  wallpaper?: WallpaperModel;

  // header kind of image
  header?: HeaderModel;

  // scroll
  scroll?: ScrollType;

  // logic stuff
  followUps?: FollowUpModel[];

  // options
  options?: OptionModel[];

  // optional badge
  badge?: string;
}

export interface HeaderModel {
  filename: string;
  extension?: MediaExtension;
  flexible?: boolean;
  alt?: string;
}

export interface WallpaperModel {
  image: string;
  gradient?: boolean;
}

// immediate is only used in the chat
export type ScrollType = "off" | "onPass" | "afterDelay" | "immediate";

export type QuestionType =
  | "LevelQuestion"
  | "InitialLevelView"
  | "TargetLevelView"
  | "QuestionView"
  | "PreparingProgram"
  | "EmbeddedQuestion"
  | "ThankYouView"
  | "ProgramView"
  | "PostPurchase"
  | "SignupView";

// these views are either post purchase or reactivate flow related
export enum StaticViewIdEnum {
  POST_PURCHASE = 1000,
  THANK_YOU = 1001,
  VERIFY_EMAIL = 1002,
  UPDATE_EMAIL = 1003,
  EMAIL_EXISTS = 1004,
  CONGRATULATIONS = 1005,
  NEXT_STEP = 1006,
  STARTING_LEVEL_TEST = 1007,
  START_LEVEL_TEST = 1008,
  REACTIVATE_OR_NEW = 1009,
  REACTIVATE = 1010,
  NEW_CONFIRM = 1011,
  LOGIN_WITH_PASSWORD = 1012,
}

export enum StaticViewPathEnum {
  POST_PURCHASE = "post-purchase",
  THANK_YOU = "gracias",
  VERIFY_EMAIL = "verificar-email",
  UPDATE_EMAIL = "actualizar-email",
  EMAIL_EXISTS = "email-ya-existe",
  CONGRATULATIONS = "enhorabuena",
  NEXT_STEP = "siguiente-paso",
  STARTING_LEVEL_TEST = "test-de-nivel-inicial",
  START_LEVEL_TEST = "empezar-test-de-nivel",
  REACTIVATE_OR_NEW = "reactivar-o-nueva",
  REACTIVATE = "reactivar",
  NEW_CONFIRM = "nueva-confirmacion",
  LOGIN_WITH_PASSWORD = "usuario",
}
