/* eslint-disable react/jsx-no-target-blank */
import { ViewType } from "models/view";
import { TagMap } from "./tag";
import { renderToStaticMarkup } from "react-dom/server";
import { ColorCode } from "theme/colors";

export const toHTML = (__html: string = "") => {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: __html.replace(/\n/gi, "<br/>"),
      }}
    ></span>
  );
};

export const createLinkTag = (
  href: string,
  text: string,
  blank: boolean = false,
) => {
  const C = (
    <a
      href={href}
      style={{ color: ColorCode.LIGHT_BLUE }}
      rel={blank ? "noreferrer" : undefined}
      target={blank ? "_blank" : undefined}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
  return renderToStaticMarkup(C);
};

export const replaceTags = (text: string = "", tags: TagMap) => {
  // replace tags
  let formatted = text;
  const keys = Object.keys(tags).sort((a, b) => b.length - a.length); // sort biggest first
  keys.forEach((key) => {
    const val = tags[key];

    // do not continue, if value is not defined
    if (val === undefined) {
      return;
    }

    // with blue highlight
    const highlightRegexp = new RegExp(`##${key}##`, "g");
    formatted = formatted.replace(highlightRegexp, highlight(val.toString()));

    // without highlight
    const normalRegexp = new RegExp(`#${key}#`, "g");
    formatted = formatted.replace(normalRegexp, val.toString());
  });

  // get rid of all unfilled tags
  formatted = formatted.replace(/#+[a-zA-Z0-9_]+#+/gi, "");

  // return all good
  return formatted;
};

export const breakAll = (text: string) => {
  const C = (
    <span
      style={{ wordBreak: "break-all" }}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
  return renderToStaticMarkup(C);
};

export const nowrap = (text: string) => {
  const C = (
    <span
      style={{ whiteSpace: "nowrap" }}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
  return renderToStaticMarkup(C);
};

export const capitalize = (text: string) => {
  const modifiedText = text;
  return [modifiedText[0].toLocaleUpperCase(), modifiedText.substring(1)].join(
    "",
  );
};

export const color = (str: string, color: string) => {
  const C = (
    <span style={{ color }} dangerouslySetInnerHTML={{ __html: str }} />
  );
  return renderToStaticMarkup(C);
};

export const highlight = (str: string) => color(str, ColorCode.BLUE);

export const highlightEmail = (view: ViewType, str: string) =>
  color(str, view === "light" ? ColorCode.BLACK : ColorCode.WHITE);
