import { useTheme } from "hooks/theme";
import { getMediaUrl } from "lib/media";
import { useRouter } from "next/router";
import React, { FunctionComponent } from "react";
import { StyledDots, StyledLeftOverAndDots, StyledWait } from "./wait.styles";

interface WaitProps {
  className?: string;
  text: string;
}

const Wait: FunctionComponent<WaitProps> = ({ className, text }) => {
  const router = useRouter();
  const theme = useTheme();
  const lastSpaceIndex = text.lastIndexOf(" ");
  const parsedText = text.substring(0, lastSpaceIndex);
  const leftOver = text.substring(lastSpaceIndex);
  return (
    <StyledWait className={className} $theme={theme}>
      {parsedText}
      <StyledLeftOverAndDots>
        {leftOver}
        <StyledDots
          src={getMediaUrl("loaders/DottedLoading", router, {
            extension: "gif",
          })}
        />
      </StyledLeftOverAndDots>
    </StyledWait>
  );
};

export default React.memo(Wait);
