import { read, write } from "lib/storage";
import { ConfigModel, CONFIG_STATE_KEY } from "models/config";
import { useRouter } from "next/router";
import React, {
  createContext,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import { fetchConfig } from "services/config";

interface ConfigContextInterface {
  config: ConfigModel;
  updateConfig: (newConfig: ConfigModel) => void;
}

export const ConfigContext = createContext<ConfigContextInterface | undefined>(
  undefined
);

interface ConfigProviderProps {
  clean?: boolean;
}

const ConfigProvider: FunctionComponent<ConfigProviderProps> = ({
  children,
  clean = false,
}) => {
  // fetch the initial config from localStorage, unless we want a clean installation
  const cachedConfig = clean ? undefined : read<ConfigModel>(CONFIG_STATE_KEY);
  let [config, toggleConfig] = useState<ConfigModel | undefined>(cachedConfig);

  const router = useRouter();
  const { isReady } = router;

  const updateConfig = (newConfig: ConfigModel) => {
    // update the local state
    toggleConfig(newConfig);
    console.info(`Updated Config Context`);

    // persist
    write(CONFIG_STATE_KEY, newConfig);
  };

  useEffect(() => {
    // do not continue if we already have config
    if (config) {
      console.info("ConfigProvider");
      console.info(` ==> Context is set, uuid = ${config.uuid}`);
      return;
    }

    // do not continue, if the router is not ready yet
    if (!isReady) {
      return;
    }

    const init = async () => {
      console.info("ConfigProvider");
      console.info(` ==> Context is empty, trying to load...`);

      try {
        // parse tracking ids
        const campaignId = router.query["cid"] as string;
        const versionId = router.query["vid"] as string;

        // fetch config and store to session storage (plus local state)
        console.info(`campaignId = ${campaignId}`);
        const newConfig = await fetchConfig({ campaignId, versionId });
        console.info(` ==> Loaded from server. UUID = ${newConfig.uuid}`);

        // update to state
        updateConfig(newConfig);
      } catch (err) {
        console.error("Fetching config failed!");
      }
    };

    init();
  }, [isReady]);

  if (!config) {
    return null;
  }

  return (
    <ConfigContext.Provider value={{ config, updateConfig }}>
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;
