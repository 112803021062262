import platform from "platform";

export const OLD_OS_KEY = "oldOSWarning";

export enum PlatformType {
  ANDROID = "android",
  APPLE = "apple",
}

export const getPlatform = (): PlatformType => {
  if (platform.os && platform.os.family) {
    if (platform.os.family.toLocaleLowerCase() === PlatformType.ANDROID) {
      return PlatformType.ANDROID;
    }
  }

  // default to 
  return PlatformType.APPLE;
};

const isDesktop = () => {
  if (platform && platform.os && platform.os.family) {
    if (platform.os.family === "OS X" || platform.os.family === "Windows") {
      return true;
    }
  }
  return false;
};

export const getPlatformVersion = (): number => {
  if (platform.os) {
    const version = parseInt(platform.os.version || "999");
    return version;
  }
  return 999;
};

export const isLegacyPlatform = () => {
  // do not check desktops
  if (isDesktop()) {
    return false;
  }

  const platformName = getPlatform();
  const platformVersion = getPlatformVersion();
  return (
    (platformName === PlatformType.ANDROID && platformVersion <= 10) ||
    (platformName === PlatformType.APPLE && platformVersion <= 13)
  );
};
