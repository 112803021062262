import { useConfig } from "hooks/config";
import { buildState } from "lib/state";
import { read, write } from "lib/storage";
import { STATE_KEY } from "models/questionnaire";
import { StateModel } from "models/state";
import React, {
  createContext,
  FunctionComponent,
  useEffect,
  useState,
} from "react";

interface StateContextInterface {
  state: StateModel;
  updateState: (newState: StateModel) => void;
}

export const StateContext = createContext<StateContextInterface | undefined>(
  undefined,
);

const StateProvider: FunctionComponent = ({ children }) => {
  const config = useConfig();

  let [state, toggleState] = useState<StateModel | undefined>(
    read<StateModel>(STATE_KEY),
  );

  const updateState = (newState: StateModel) => {
    // update the local state
    toggleState({ ...newState });
    console.info(`Updated State Context`);

    // persist
    write(STATE_KEY, newState);
  };

  useEffect(() => {
    const init = async () => {
      console.info("StateProvider");

      // check if config exists
      if (!state) {
        console.info(` ==> Context is empty, building new from config...`);
        state = { ...buildState(config) };
      } else {
        console.info(` ==> Loaded from localStorage.`);
      }

      // store to local state and persist
      updateState(state);
    };

    init();
  }, []);

  if (!state) {
    return null;
  }

  return (
    <StateContext.Provider value={{ state, updateState }}>
      {children}
    </StateContext.Provider>
  );
};

export default StateProvider;
