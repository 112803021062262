import { DEVICE_ID_KEY } from "models/config";
import { read, write } from "./storage";
import { v4 } from "uuid";

const env = process.env.NEXT_PUBLIC_NAME;

if (!env) {
  throw new Error(
    "process.env.NEXT_PUBLIC_NAME is not defined, check your .env config!",
  );
}

export const isLocal = () => env === "local";

export const isDev = () => env === "dev";

export const isStage = () => env === "stage";

export const isProduction = () => env === "production";

export const getEnv = () => env;

export const isWindow = () => {
  return typeof window !== "undefined";
};

export const getPublicUrl = () => {
  return process.env.NEXT_PUBLIC_ME_URL || "";
};

const API_URL_KEY = "apiUrl";

export const setApiUrl = (newApiUrl: string) => {
  write<string>(API_URL_KEY, newApiUrl);
};

export const getAllApiUrls = () => {
  return (process.env.NEXT_PUBLIC_API_URL || "").split(",");
};

export const getApiUrl = () => {
  const apiUrl = read<string>(API_URL_KEY) || getAllApiUrls()[0] || "";
  return apiUrl;
};

export const resetApiUrl = () => {
  setApiUrl(getAllApiUrls()[0] || "");
};

export const generateDeviceId = () => {
  let deviceId = read<string>(DEVICE_ID_KEY);
  if (!deviceId) {
    deviceId = v4();
    write(DEVICE_ID_KEY, deviceId);
  }
  return deviceId;
};
