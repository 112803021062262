import React, { FunctionComponent, useEffect } from "react";
import { useRouter } from "next/router";
import { restore, restoreSession } from "lib/restore";
import { useTranslations } from "hooks/translations";
import { useConfig, useUpdateConfig } from "hooks/config";
import Loader from "components/loader";
import { useUpdateQuestionnaireState } from "hooks/state";
import { useUpdateQuestionnaire } from "hooks/questionnaire";
import { QuestionnaireVersion } from "models/questionnaire";
import { ParsedUrlQuery } from "querystring";
import { write } from "lib/storage";

// THIS REQUIRES BETTER LOGIC ==> CHECK THE VALUE IS A VALID VARIANT NUMBER
const parseTarget = (query: ParsedUrlQuery) => {
  const tempTarget = query["target"] as string;
  if (tempTarget === undefined || tempTarget.length > 2) {
    return undefined;
  }
  return tempTarget as QuestionnaireVersion;
};

export const TARGET_KEY = "target";

const LinkView: FunctionComponent = () => {
  const router = useRouter();
  const config = useConfig();
  const updateConfig = useUpdateConfig();
  const updateQuestionnaire = useUpdateQuestionnaire();
  const updateQuestionnaireState = useUpdateQuestionnaireState();
  const t = useTranslations();
  const tempToken = router.query["token"] as string;

  // variant override mechanism
  const target = parseTarget(router.query);

  useEffect(() => {
    const init = async () => {
      if (!tempToken) {
        return;
      }

      try {
        // restore session, modifies config possibly
        await restoreSession({ config, updateConfig, tempToken });
      } catch (err) {
        // store target to storate
        write(TARGET_KEY, target);

        // link expired
        router.replace(t("/login/expired"));
        return;
      }

      // restore the whole state
      await restore({
        t,
        target,
        config,
        router,
        updateQuestionnaire,
        updateQuestionnaireState,
      });
    };
    init();
  }, [tempToken]);

  return <Loader />;
};

export default LinkView;
