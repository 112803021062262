import Script from "next/script";
import React, { Fragment, FunctionComponent } from "react";

const OneTrust: FunctionComponent = () => {
  return (
    <Fragment>
      <Script
        id="onetrust-load"
        src={process.env.NEXT_PUBLIC_ONETRUST_URL}
        data-document-language="true"
        data-domain-script={process.env.NEXT_PUBLIC_ONETRUST_DOMAIN}
        strategy="afterInteractive"
      />
    </Fragment>
  );
};

export default OneTrust;
